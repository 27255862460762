<template>
  <div>
    <Header :seet="number" />
      <el-row type="flex" class="account—settings">
        <el-col :span="3" style="width:15.3%;"></el-col>
        <el-col :span="17">
          <div class="settings_box">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item @click.native="goto()">商城首页</el-breadcrumb-item>
              <el-breadcrumb-item>工作台</el-breadcrumb-item>
            </el-breadcrumb>
            <div style="display:flex;">
              <div class="box_left">
                <LeftHurdle/>
              </div>
              <div class="work_box">
                <div class="work_top" style="width: 1000px;">
                  <div class="flex-start topContent">
                    <div class="flex-start">
                        <div><img src="@/assets/image/userimg.png" alt="" style="width:70px"></div>
                        <div class="work-infor">
                            <div  class="infor-name"><span>禹佳商贸</span>, 欢迎您！</div>
                            <div v-for="(item, index) in identity" :key="index">{{ item }}</div>
                        </div>
                    </div>
                    <div class="top-bind" style="max-width: 250px;">
                      <div class="flex-start">
                        <div style="word-break: break-all;">绑定邮箱：<span v-if="email==null" @click="gotoEmail()">去绑定</span><span v-else>{{email}}</span></div>
                        <span style="padding-left:20px;width: 28px;" v-show="email!=null" @click="goemail()">查看</span>
                      </div>
                      <div class="flex-start bind-div" v-if="isApproval()">
                        <div>增票资质：{{InvoiceFlag(invoiceFlag)}}</div>
                        <span style="padding-left:20px;" @click="Qualification()">查看</span>
                      </div>
                    </div>
                    <div v-show="!$store.state.externalId && identity.indexOf('采购员') > -1">
                      <div style="margin-bottom: 10px">待支付订单: {{notPayNums}} 单</div>
                      <div>待支付金额:￥ <span>{{notPayAmoumt}} 元</span> </div>
                    </div>
                    <div>
                      <div v-if="balance != null" style="margin-bottom: 10px">预充值可用余额: <span style="color: #cd9f49">￥ {{balance}} 元</span> <span v-show="identity.indexOf('外部企业管理员') > -1" style="color: #cd9f49;margin-left: 10px;cursor: pointer;" @click="jumpRecharge">充值</span></div>
                      <div v-if="creditLine != null">授信额度余额: <span style="color: #cd9f49">￥ {{creditLine}} 元</span> </div>
                    </div>
                  </div>
                </div>
                <div v-if="authorityJudgment([1, 2, 3, 4, 5])" class="work-infor-box">
                  <div class="work-infor-top flex-between">
                    <div>我的订单</div>
                    <div @click="goOrder()">查看全部订单</div>
                  </div>
                  <div>
                    <div class="work-goods" v-for="(item,index) in goodsList" :key="index">
                      <div class="flex-start" >
                        <div style="width:55%;overflow-x: auto;" class="overagin flex-start">
                          <div class="work-img" v-for="(itm,indx) in item.orderGoodsList" :key="indx">
                            <img :src="itm.goodsPic" alt="">
                          </div>
                        </div>
                        <div style="width:20%;">
                          <div>共{{item.nums}}件商品</div>
                          <div>￥{{item.totalAmount}}</div>
                          <div>{{item.createTime}}</div>
                        </div>
                        <div style="width:20%;">
                          <div>{{
                                stateName(
                                    item.auditState,
                                    item.state
                                )
                            }}</div>
                        </div>
                        <div>
                          <div @click="orderDetails(item)">查看</div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </el-col>
        <!-- <el-col :span="3" style="width:15.5%;"></el-col> -->
    </el-row>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Layout/header.vue'
import Footer from '@/components/Layout/footer.vue'
import LeftHurdle from '@/components/Layout/leftHurdle.vue'
import { addressList,workbench,getWorkInfo } from "@/api/order/order";
import { authorityJudgment } from "@/utils/util"
export default {
  components: { Header,Footer,LeftHurdle },
  data () {
      return{
        authorityJudgment: authorityJudgment,
        number:'4',
        pageSize:10,
        goodsList:[],
        identity: [],
        invoiceFlag:'',
        email:'',
        notPayAmoumt:0,
        notPayNums:0,
        creditLine: null,
        balance: null
      }
  },
  computed:{
    
    stateName() {
        return (auditState, state) => {
            if (auditState === 1 || auditState === 20) {
                if (state == 7) {
                    return "已取消";
                } else {
                    return "待审核";
                }
            } else if (
                auditState === 11
            ) {
                if (state == 7) {
                    return "已取消";
                } else {
                    return "等待总部审核";
                }
            }else if (
                auditState === 2 ||
                auditState === 3 ||
                auditState === 4 ||
                auditState === 5 ||
                auditState === 6 ||
                auditState === 21 ||
                auditState === 22
            ) {
                if (state == 7) {
                    return "已取消";
                } else {
                    return "等待后台审核";
                }
            } else if (auditState === 7 || auditState === 0) {
                if (state == 1) {
                    return "待付款";
                } else if (state == 2) {
                    return "待发货";
                } else if (state == 3) {
                    return "待收货";
                } else if (state == 4) {
                    return "待评价";
                } else if (state == 5) {
                    return "已完成";
                } else if (state == 6) {
                    return "退货退款";
                } else if (state == 7) {
                    return "已取消";
                } else if (state == 11) {
                    return "待退款";
                } else {
                    return "已通过";
                }
            } else if (auditState === 9) {
                return "已驳回";
            }
        };
    },
    InvoiceFlag(){
      return (invoiceFlag) => {
        if(invoiceFlag==1){
          return '有'
        }else{
          return '无'
        }
      }
    }
  },
  
  created(){
    this.orderList(1)
    workbench().then((data)=>{
      if(data&&data.code=='00000'){
        data.data.roleList.forEach((item) => {
          this.identity.push(item.roleName)
        })
        // this.identity=data.data.roleList[0].roleName
        this.email=data.data.email
        this.invoiceFlag= data.data.invoiceFlag
        this.creditLine = data.data.creditLine
        this.balance = data.data.balance
      }
    })
    getWorkInfo().then((data)=>{
      if(data.code=='00000'){
        this.notPayAmoumt=data.data.notPayAmoumt
        this.notPayNums=data.data.notPayNums
      }
    })
  },
  methods:{
    // 是否是有审批员和商品管理员
    isApproval() {
      if (this.getisRepeat([...this.identity, ...['管理员']])) {
        return true
      } else if (this.getisRepeat([...this.identity, ...['采购员']])) {
        return true
      } else if (this.getisRepeat([...this.identity, ...['外部企业管理员']])) {
        return true
      } else {
        return false
      }
    },
    // 判断是否有重复数据
    getisRepeat(arr) {
      return arr.length != Array.from(new Set(arr)).length
    },
    jumpRecharge() {
      this.$router.push({ name: 'MyTopUp'})
    },
    goOrder(){
      this.$router.push({ name: 'MyOrder' })
    },
    orderList(val) {
      let data = {
          myPage: {
              pageNum: val,
              pageSize: this.pageSize,
          },
          status:'',
      };
      addressList(data).then((data) => {
        if (data && data.code === "00000") {
          if (data.data.records.length == 0) {
              this.goodsList = [];
          } else {
              this.goodsList = [];
              this.goodsList = data.data.records;
              this.goodsList.forEach(item=>{
                  item.orderGoodsList.forEach(itm=>{
                      if(itm.sourceType==1){
                          itm.goodsPic="http://img13.360buyimg.com/n1/"+itm.goodsPic
                      }
                  })
              })
          }
        }
      });
    },
    orderDetails(item) {
      // this.$router.push({
      //   name: "OrderDetails",
      //   query: { item: JSON.stringify(item) },
      // });
      let obj = {
          orderNo: item.orderNo,
          invoiceId: item.invoiceId,
          id: item.id
      }
      if(this.$routerWin){
          const { href } = this.$router.resolve({ 
              name: "OrderDetails",
              query: { item: JSON.stringify(obj) },
          });
          window.open(href,'_blank');
      } else {
          this.$router.push({
              name: "OrderDetails",
              query: { item: JSON.stringify(obj) },
          });
      }
    },
    goto(){
      this.$router.push({ name: 'Index'})
    },
    Qualification(){
      this.$router.push({ name: 'AddedQualification'})
    },
    goemail(){
      this.$router.push({ name: 'BindEmail',params:{email: this.email}})
    },
    gotoEmail(){
      this.$router.push({ name: 'BindEmail'})
    }
  }

}
</script>

<style lang="scss">
.topContent {
  margin: 15px;
  display: flex;
  justify-content:space-between;
}
.settings_box{
  width: 100%;
  margin: 30px 0;
  .el-breadcrumb{
    font-size: 18px;
    padding-bottom: 20px;
  }
  .box_left{
    margin-right: 20px;
  }
  .work_box{
    width: 83%;
    .work_top{
        width: 100%;
        font-size: 12px;
        border: 1px solid #999;
        margin-bottom: 10px;
        .top-box{
            margin-right: 20%;
        }
        .work-infor{
            margin-left: 10px;
            color: #999;
            font-size: 12px;
            .infor-name{
                color: black;
                margin: 7px 0;

            }
        }
        .top-bind{
          // padding-right: 10%;
          font-size: 12px;
          // border-right: 1px solid #999;
          .bind-div{
            margin-top: 10px;
          }
        }
    }
    .work-infor-box{
      width: 100%;
      border: 1px solid #999;
      font-size: 14px;
      .work-infor-top{
        padding: 8px 10px;
        border-bottom: 1px solid #999;
        background: #e1e1e1;
      }
      .work-goods{
        padding: 10px;
        border-bottom: 1px solid #999;
        .work-img{
          margin-right: 10px;
          img{
            width: 70px;
          }
        }
         .overagin::-webkit-scrollbar {
            width: 4px;    
            height: 8px;
        }
        .overagin::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.4);
            background: rgba(0,0,0,0.1);
        }
        .overagin::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.4);
            border-radius: 0;
            background: rgba(0,0,0,0);

        }
      }
    }
  }
}
</style>